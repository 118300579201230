@import 'react-phone-number-input/style.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-loading-skeleton/dist/skeleton.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------------------- GLOBAL ------------------------- */
a {
  text-decoration: none;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-bold {
  margin: 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

.on,
.green {
  font-size: 2em;
  color: rgba(0, 128, 0, 0.836);
  transform: all 0.5s;
}

.off,
.red {
  font-size: 2em;
  color: rgba(226, 86, 43, 0.747);
  transform: all 0.5s;
}

.red {
  font-size: 1.2em;
}

.open-model {
  font-size: 2em;
  color: blue;
  cursor: pointer;
}

/* ------------------------- MAP ------------------------- */
.full-box {
  height: 50vh;
  max-width: initial;
}

.map-input-box {
  display: flex;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  margin: 0.5rem auto;
}

.map-input-box input {
  border-radius: 10px 0 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-right: 0;
  padding: 0.375rem 0.75rem;
}

.map-input-box input:focus-visible {
  outline: none;
}

.pac-container {
  z-index: 1055;
}

.map-input-box button {
  border-radius: 0 10px 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-left: 0;
}

@media (max-width: 400px) {
  .map-input-box {
    flex-wrap: wrap;
    right: 3.5rem;
  }

  .map-input-box input {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    margin-bottom: 0.5rem;
    padding: 0.375rem 0.75rem;
  }

  .map-input-box button {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.8);
  }
}

/* ------------------------- TABLE ------------------------- */
table.table {
  margin-bottom: 0;
}

.table-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.table-bordered>:not(caption)>*>* {
  border-width: 0 1px;
}

th,
td {
  vertical-align: middle !important;
  white-space: nowrap;
}

.td-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all 0.5s !important;
}

.td-img:hover {
  transform: scale(3);
  transition: all 0.5s !important;
}

/* ------------------------- WRAPPER ------------------------- */
.main-wrapper {
  display: flex;
  min-height: 100%;
}

.body-wrapper {
  transition: margin-left .3s ease-in-out, width .3s ease-in-out !important;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  flex: 1;
  transition: width .3s ease-in-out;
}

.body-wrapper.mini-body {
  margin-left: 250px;
}

.body-wrapper.full-body {
  margin-left: 4.6rem;
}

.sidebar-wrapper {
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22) !important;
  background: linear-gradient(rgba(15, 145, 122, 1) 0%, rgba(131, 227, 124, 1) 75%);
  z-index: 1038;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width .3s ease-in-out;
}

/* ------------------------ LOGIN SCREEN ------------------------- */
.login-page {
  background-color: #e9ecef;
}

.login-box {
  width: 90%;
}

.login-logo {
  font-size: 2.1rem;
  font-weight: 300;
  margin-bottom: 0.9rem;
  text-align: center;
}

.login-logo a {
  color: #495057;
}

@media (min-width: 576px) {
  .login-box {
    width: 360px;
  }
}

/* ------------------------ HEADER ------------------------- */
.header {
  border-bottom: 1px solid #dee2e6;
  z-index: 1020;
  background: linear-gradient(120deg, rgba(15, 145, 122, 1) 0%, rgba(131, 227, 124, 1) 75%);
}

.dropdown-toggle:hover,
.dropdown-toggle,
.dropdown:hover {
  background-color: transparent;
  border: none;
}

.dropdown button {
  background: transparent !important;
}

.right-profile-logo>img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

/* ------------------------ VIEW CARD ------------------------- */
.card-title {
  display: inline-block;
}

.card-tools {
  float: right;
  cursor: pointer;
}

/* ------------------------ INPUT PHONE NUMBER ------------------------- */
.form-control.PhoneInput {
  display: flex;
}

.form-control.PhoneInput .PhoneInputInput,
.form-control.PhoneInput .PhoneInputInput:focus-visible {
  border: none;
  outline: none;
}

/* ------------------------ JODIT ------------------------- */
.jodit-workplace>div {
  max-height: 40vh !important;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right .jodit-status-bar-link,
.jodit-add-new-line.jodit-add-new-line_after {
  display: none;
}

/* ---------------------------STEPS ---------------------- */
/* Horizontal */
.step {
  width: 100%;
}

.step-md-item {
  display: inline-block;
  flex: 1;
  overflow: visible;
  position: relative;
  vertical-align: top;
}

.step-md-item-border {
  position: absolute;
  top: -1px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline: calc(50%) 0;
  padding: 0;
}

.step-md-item-border::after {
  width: calc(100% - 24px);
  height: 3px;
  margin-inline-start: 12px;
  display: inline-block;
  background: var(--bs-secondary);
  border-radius: 1px;
  transition: background 0.3s;
  content: "";
}

.step-md-item:last-child>.step-md-item-border {
  display: none;
}

.step-md-item-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-inline-start: calc((100% - 8px) / 2);
  padding-inline-end: 0;
  line-height: 8px;
  border: 0;
  background-color: var(--bs-secondary);

  margin-top: 0;
  margin-bottom: 0;
  margin-inline-end: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 32px;
}

.step-md-item-dot::after {
  position: absolute;
  top: -12px;
  inset-inline-start: calc((8px - calc(40 * 1.5)) / 2);
  width: calc(40px * 1.5);
  height: 32px;
  background: transparent;
  content: "";
}

.step-md-item-content {
  overflow: hidden;
  text-align: center;
}


/* Vertical */
.step-item {
  position: relative;
}

.step-item-border {
  position: absolute;
  top: 12px;
  padding: 16px 0 8px;
  width: 1px;
  height: 100%;
}

.step-item-border::after {
  margin-inline-start: 3.5px;
  width: 1px;
  height: 100%;
  display: inline-block;
  width: 100%;
  background: var(--bs-secondary);
  border-radius: 1px;
  transition: background 0.3s;
  content: "";
}

.step-item:last-child>.step-item-border {
  display: none;
}

.step-item-dot {
  margin-top: 10px;
  margin-inline-end: 16px;
  float: left;
  width: 8px;
  height: 8px;
  border-radius: 32px;
  display: inline-block;
  background-color: var(--bs-secondary);
}

.step-item-dot::after {
  position: absolute;
  top: -12px;
  inset-inline-start: calc((8px - calc(40 * 1.5)) / 2);
  width: calc(40px * 1.5);
  height: 32px;
  background: transparent;
  content: "";
}

.step-item-content {
  overflow: hidden;
}

[class^="step-"].active>[class$="dot"],
[class^="step-"].active>[class$="border"]::after {
  /* .step-md-item.active>.step-md-item-dot,
.step-item.active>.step-item-dot,
.step-item.active>.step-item-border::after,
.step-md-item.active>.step-md-item-border::after { */
  background: var(--bs-primary);
}

.dashboard_cards{
  width:23.7%;
  padding:22px;
  cursor: pointer;
  z-index:1;
  opacity: 0.9;
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .dashboard_cards {
    width:48%;
  }
}

@media only screen and (max-width: 767px){
  .dashboard_cards{
      width:100%;
      padding:23px;
  }
}