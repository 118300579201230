/* NX = not expanded */

.sidebar {
	height: calc(100% - (3.5rem + 1px));
	overflow-x: hidden;
	overflow-y: initial;
	padding-bottom: 0;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0;
}

/* set the width of the scrollbar */
.sidebar::-webkit-scrollbar {
	width: 8px;
}

/* set the background color of the track */
.sidebar::-webkit-scrollbar-track {
	background-color: #bbb;
}

.sidebar::-webkit-scrollbar-thumb {
	/* set the color of the thumb */
	background-color: #7a797a;
	/* round the corners of the thumb */
	border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
	/* set the color of the thumb on hover */
	background-color: #323432b4;
}

.side-nav-container {
	position: relative;
	width: 250px;
	height: 100vh;
	transition: width .3s ease-in-out;
	margin-left: 0;
}

.side-nav-container-NX {
	width: 4.6rem;
}

.sidebar-overlay {
	background-color: rgba(0, 0, 0, .2);
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1030;
}

@media (max-width: 768px) {
	.side-nav-container-NX {
		margin-left: -4.6rem;
	}

	.body-wrapper.full-body,
	.body-wrapper.mini-body {
		margin-left: 0;
	}

	.sidebar-overlay {
		display: block;
	}
}

.brand-link {
	border-bottom: 1px solid #4b545c;
	text-decoration: none;
	width: 250px;
	color: rgba(255, 255, 255, .8);
	display: block;
	font-size: 1.25rem;
	line-height: 1.5;
	padding: 0.8125rem 0.5rem;
	transition: width .3s ease-in-out;
	white-space: nowrap;
}

.side-nav-container-NX .brand-link {
	width: 4.6rem;
}

.brand-link:hover {
	color: #fff;
}

.user-panel {
	position: relative;
	border-bottom: 1px solid #4f5962;
	overflow: hidden;
	white-space: nowrap;
}

.user-panel .info {
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	padding: 5px 5px 5px 10px;
}

.menu-item {
	display: flex;
	cursor: pointer;
	text-decoration: none;
	border-radius: 0.25rem;
}

.nav-sidebar .menu-item .nav-link {
	white-space: nowrap;
}

.nav-sidebar .menu-item .nav-link p {
	display: inline;
	margin: 0;
	white-space: nowrap;
}

.nav-sidebar .menu-item.menu-item-NX .nav-link p,
.side-nav-container-NX .user-panel .info,
.side-nav-container-NX .brand-link .brand-text {
	animation: fadeOut 0.3s both;
	/* -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; */
	visibility: hidden;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.menu-item .nav-link,
.info-text {
	color: #001c4e;
}

.info-text {
	color: rgba(225, 225, 225, 0.9);
	vertical-align: middle;
}

.menu-item .nav-link {
	width: 100%;
}

.menu-item.menu-item-NX .nav-link {
	width: 3.2rem;
}

.menu-item.active-item .nav-link {
	background-color: rgba(255, 255, 255, .3);
}

.menu-item .nav-link:hover {
	background-color: rgba(255, 255, 255, .1);
}

.menu-item:hover .nav-link,
.menu-item.active-item .nav-link,
.menu-item:hover .nav-link:hover,
.info-text:hover {
	color: #fff;
}

.nav-sidebar .nav-item>.nav-link {
	position: relative;
	margin-bottom: 0.2rem;
}

.nav-pills .nav-link {
	border-radius: 0.25rem;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}

.icon-md {
	font-size: 1.2rem;
}